<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page">
    <form enctype="multipart/form-data">
      <vx-card>
        <vs-table :data="formule">
          <template slot-scope="{data}" >
            <vs-tr>
              <vs-td class="headTab" style="width: 30%">
                Titre<sup>*</sup>
              </vs-td>
              <vs-td >
                <vs-input
                  class="w-full mt-6"
                  placeholder="Titre "
                  v-model="libelle"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab" style="width: 30%">
                Formule <sup>*</sup>
              </vs-td>
              <vs-td >
                <select class="vs-select w-full" v-model="formuleCommande" @change="getFormule(formuleCommande)">
                  <option v-for="item in formules" :key="item.id" :value="'/api/formule_commandes/'+item.id">{{item.libelle }}</option>
                </select>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
               Date de début de l'offre <sup>*</sup>
              </vs-td>
              <vs-td :data="dateDebut">
                <flat-pickr :config="configdateTimePicker" placeholder="Date de fin de l'Offre"
                            v-model="dateDebut"/> </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Date de fin de l'offre <sup>*</sup>
              </vs-td>
              <vs-td :data="dateFin">
                <flat-pickr :config="configdateTimePicker" placeholder="Date de fin de l'Offre"
                            v-model="dateFin"/>    </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Liste des négociateurs
              </vs-td>
              <vs-td >
                <vue-csv-import
                  v-model="negociateurs"
                  :map-fields="['email']">

                  <template slot="hasHeaders" slot-scope="{headers, toggle}">
                    <label  style="display: none !important">
                      <input type="checkbox" id="hasHeaders" value="true" v-model="headers" @change="toggle">
                      Headers?
                    </label>
                  </template>

                  <template slot="error">
                    Le type est invalide
                  </template>

                  <template slot="thead">
                    <tr>
                      <th>Mes colonnes </th>
                      <th>Colonne</th>
                    </tr>
                  </template>

                  <template slot="next" slot-scope="{load}">
                    <button @click.prevent="load">Charger</button>
                  </template>
                </vue-csv-import>   </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Gratuit
              </vs-td>
              <vs-td :data="data[0].free">
                <vs-checkbox class="float-left" vs-value="true" v-model="data[0].free" @change="updatePrice"> Oui </vs-checkbox>
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].choixNbRdv && !free">
              <vs-td class="headTab">
                Prix / Nombre de rendez-vous
              </vs-td>
              <vs-td :data="data[0].prix">
                <vs-tr>
                  <vs-td>
                    <vs-input
                      class="w-full mt-6"
                      type="number"
                      placeholder="Prix"
                      :label="data[0].prix[0].nb+' RDV'"
                      v-model="data[0].prix[0].prix"
                      :disabled="free"
                    />
                  </vs-td>
                  <vs-td>
                    <vs-input
                      class="w-full mt-6"
                      type="number"
                      placeholder="Prix"
                      :label="data[0].prix[1].nb +' RDV'"
                      v-model="data[0].prix[1].prix "
                      :disabled="free"
                    />
                  </vs-td>
                </vs-tr>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Choix de nombre de rendez-vous<br>
              </vs-td>
              <vs-td :data="data[0].choixNbRdv">
                <vs-checkbox class="float-left" vs-value="true" v-model="data[0].choixNbRdv"  :disabled="free"> Oui </vs-checkbox>
              </vs-td>
            </vs-tr>
            <vs-tr v-if="!data[0].choixNbRdv">
              <vs-td class="headTab" >
                Prix / Nombre de rendez-vous fixe<br>
                <small>Si le négociateur ne peut pas choisir le nombre de rendez-vous, veuillez fixer le nombre et le prix pour tous les négociateurs</small>

              </vs-td>
              <vs-td :data="data[0].prix">
                <vs-tr>
                  <vs-td>
                    <vs-input
                      class="w-full mt-6"
                      type="number"
                      placeholder="Nombre Rdv"
                      label="Nombre de rendez-vous"
                      v-model="NombreRdvDefaut"
                    />
                  </vs-td>
                  <vs-td>
                    <vs-input
                      class="w-full mt-6"
                      type="number"
                      placeholder="Prix"
                      label="Prix"
                      :disabled="free"
                      v-model="prixDefaut"
                    />
                  </vs-td>
                </vs-tr>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Description<sup>*</sup>
              </vs-td>
              <vs-td :data="data[0].description">
                <ckeditor :editor="editor" v-model="data[0].description" :config="editorConfig"></ckeditor>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Affichage dans le slider de la page d'accueil
              </vs-td>
              <vs-td :data="slider">
                <vs-checkbox class="float-left" vs-value="true" v-model="slider"> Oui </vs-checkbox>
              </vs-td>
            </vs-tr>
            <vs-tr v-if="slider">
              <vs-td class="headTab">
                Image Slider<sup>*</sup>(1920px * 566px)<br>
                <small>(1920px * 566px)</small>
              </vs-td>
              <vs-td >
                <vs-input
                  class="w-full mt-6"
                  type="file"
                  :name="uploadFieldName"
                  v-model="sliderFile"
                  id="mediaSlider"
                  accept="image/*"
                  @change="filesChange($event.target.name, $event.target.files,$event.target.id); fileCount = $event.target.files.length"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Bandeau <sup>*</sup><br>
                <small>(1920px * 272px)</small>
              </vs-td>
              <vs-td>
                <img v-if="data[0].mediaBandeau !== null && data[0].mediaBandeau !== undefined" :src="baseURL+data[0].mediaBandeau.contentUrl" style="width: 100%">
                <vs-input
                  class="w-full mt-6"
                  type="file"
                  :name="uploadFieldName"
                  v-model="bandeauFile"
                  id="mediaBandeau"
                  accept="image/*"
                  @change="filesChange($event.target.name, $event.target.files,$event.target.id); fileCount = $event.target.files.length"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Image pour block "Offres actuelles"<sup>*</sup><br>
                <small>(1000px * 667px)</small>
              </vs-td>
              <vs-td>
                <vs-input
                  class="w-full mt-6"
                  type="file"
                  v-model="blockFile"
                  :name="uploadFieldName"
                  id="mediaHomePage"
                  accept="image/*"
                  @change="filesChange($event.target.name, $event.target.files,$event.target.id); fileCount = $event.target.files.length"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Text bandeau<sup>*</sup>
              </vs-td>
              <vs-td :data="data[0].textBandeau">
                <ckeditor :editor="editor" v-model="data[0].textBandeau" :config="editorConfig"></ckeditor>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous à créditer
              </vs-td>
              <vs-td :data="data[0].defaultNbRdvCredit">
                <vs-input
                  class="w-full mt-6"
                  type="number"
                  v-model="data[0].defaultNbRdvCredit"
                />
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Limite de recrédit par rapport au nombre de rendez-vous commandés
              </vs-td>
              <vs-td :data="data[0].limiteRecredit">
                <vs-checkbox class="float-left" vs-value="true" v-model="data[0].limiteRecredit"> Oui </vs-checkbox>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Active
              </vs-td>
              <vs-td :data="data[0].active">
                <vs-checkbox class="float-left" vs-value="true" v-model="data[0].active"> Oui </vs-checkbox>
              </vs-td>
            </vs-tr>

          </template>
        </vs-table>
        <vs-button @click="Add()" class="mr-3 mb-2 mt-10" >Ajouter</vs-button>
      </vx-card>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/axios';
import 'flatpickr/dist/flatpickr.css';
import { upload } from '../../../FileServices/file-upload.service.js';
import flatPickr from 'vue-flatpickr-component';
import router from '@/router';
import { VueCsvImport } from 'vue-csv-import';

const STATUS_INITIAL = 0; const STATUS_SAVING = 1; const STATUS_SUCCESS = 2; const
  STATUS_FAILED = 3;
const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  components: {
    flatPickr,
    VueCsvImport,
  },
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
  },
  props: {
    id: '',
  },
  data() {
    return {
      blockFile: '',
      bandeauFile: '',
      sliderFile: '',
      NombreRdvDefaut: 0,
      prixDefaut: 0,
      headers: false,
      configdateTimePicker: {
        dateFormat: 'Y-m-d H:i',
        defaultDate: null,
        timeZoneType: '3',
      },
      formuleCommande: '',
      formules: [],
      libelle: '',
      slider: true,
      baseURL,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'file',
      dateDebut: null,
      dateFin: null,
      negociateurs: [],
      free: false,
      negociateursObjects: [],
      editorConfig: {
        toolbar: [
          { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },

          { name: 'tools', items: ['Maximize'] },
          '/',
          { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'] },
          { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
          { name: 'styles', items: ['Styles', 'Format'] },
        ],
      },
      formule: [{
        libelle: '',
        prix: [{
          nb: 5,
          prix: 0,
        },
        { nb: '10', prix: 0 },
        ],
        description: '',
        defaultNbRdvCredit: 0,
        choixNbRdv: false,
        free: false,
        textBandeau: '',
        active: false,
      }],
      formData: [],
    };
  },
  computed: {
    bandeau() {
      return this.formule.bandeau;
    },
    icone() {
      return this.formule.icone;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    updatePrice() {
      if (this.formule[0].free) {
        this.formule[0].prix[0].prix = 0;
        this.formule[0].prix[1].prix = 0;
        this.prixDefaut = 0;
        this.free = true;
        this.formule[0].choixNbRdv = false;
      } else {
        this.free = false;
      }
    },
    async importMandataries(formule) {
      const mandataries = [];
      const arrayIdsMandataries = [];
      this.negociateurs.forEach((item) => {
        mandataries.push(item.email);
      });
      await axios.post('/api/mandataries/email', { emails: mandataries }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((item) => {
            arrayIdsMandataries.push(item.email);
          });
          // this.negociateursObjects.push('/api/mandataries/'+response.data.id)
        }
        formule.negociateurs = arrayIdsMandataries;
        return formule;
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },
    validFormFormule() {
      return this.libelle !== '' && this.dateDebut !== null && this.dateFin !== null && this.formuleCommande !== '' && this.formule[0].description !== '' && this.formule[0].mediaBandeau !== '' && this.formule[0].textBandeau;
    },
    getFormule(url) {
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          this.formule = [response.data];
          if (response.data.choixNbRdv === undefined || !response.data.choixNbRdv) {
            this.NombreRdvDefaut = this.formule[0].prix[0].nb;
            this.prixDefaut = this.formule[0].prix[0].prix;
            this.formule[0].prix = [{
              nb: 5,
              prix: 0,
            },
            { nb: '10', prix: 0 },
            ];
          }
          this.updatePrice();
        }
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },
    getAllFormules() {
      axios.get('/api/formule_commandes', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          this.formules = response.data;
        }
      })
        .catch(function (error) {
          this.$toasted.show(error).goAway(1500);
        });
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    async save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      await upload(formData)
        .then((x) => {
          this.uploadedFiles = this.uploadedFiles.concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    async saveAll(formData, formule) {
      for (const value in formData) {
        await this.save(this.formData[value].formData).then(() => {
          const media = this.formData[value].fieldId;
          formule[media] = `/api/media_objects/${this.uploadedFiles[value].id}`;
        });
      }
      return formule;
    },
    filesChange(fieldName, fileList, fieldId) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map((x) => {
          formData.append(fieldName.replaceAll(' ', '-'), fileList[x], (fileList[x].name).replaceAll(' ', '-'), fieldId);
          const entry = { fieldId: formData };
          entry.fieldId = fieldId;
          entry.formData = formData;
          this.formData.push(entry);
        });
    },

    Add() {
      if (!this.validFormFormule()) {
        this.$toasted.show('Veuillez renseigner les champs obligatoires!').goAway(1500);
        return;
      }
      if (this.formule[0].active === null) {
        this.formule[0].active = false;
      }
      if (this.formule[0].choixNbRdv === null) {
        this.formule[0].choixNbRdv = false;
      }
      const formule = {
        libelle: this.libelle,
        prix: this.formule[0].prix,
        description: this.formule[0].description,
        defaultNbRdvCredit: parseInt(this.formule[0].defaultNbRdvCredit),
        choixNbRdv: Boolean(this.formule[0].choixNbRdv),
        free: Boolean(this.formule[0].free),
        slider: Boolean(this.slider),
        textBandeau: this.formule[0].textBandeau,
        limiteRecredit: Boolean(this.formule[0].limiteRecredit),
        active: Boolean(this.formule[0].active),
        formuleCommande: this.formuleCommande,
        dateDebut: this.dateDebut,
        dateFin: this.dateFin,
        // mediaSlider : this.mediaSlider ?  `/api/media_objects/${this.mediaSlider.id}` : null,
        // mediaHomePage : this.mediaHomePage ?  `/api/media_objects/${this.formule[0].mediaHomePage.id}` : null,
        mediaBandeau: this.formule[0].mediaBandeau ? `/api/media_objects/${this.formule[0].mediaBandeau.id}` : null,
      };
      if (!this.formule[0].choixNbRdv) {
        formule.prix = [{
          nb: this.NombreRdvDefaut,
          prix: this.prixDefaut,
        },
        ];
      }
      this.importMandataries(formule).then(() => {
        this.saveAll(this.formData, formule).then((formule) => {
          axios.post('/api/sous_formule_commandes', formule, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }).then(() => {
            this.$vs.notify({
              title: 'Succès',
              text: 'Offre ajoutée avec succès',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
              time: 5000,
            });
            router.push({ name: 'admin-offers' });
          }).catch(() => {
            this.$toasted.show('Le titre doit être unique.').goAway(1500);
          });
        });
      });
    },
  },
  mounted() {
    this.reset();
    this.getAllFormules();
  },
};
</script>
<style>
  .vs-table--tbody-table tr {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: #fff;
    border: 1px solid #efefef;
  }
</style>
